<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
        <div class="app-modal__in">
            <div
                class="app-modal__header d-flex f-between p-5 m-title-modal"
            >
                <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                    {{
                        $t("message.new_m", {
                            m: $t("message.payments_expense"),
                        })
                    }}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <!-- app-modal__header end -->

        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4 type-expensies" :class="mode ? 'table__myday' : 'table__mynight'">
              <el-form
              ref="form"
              status-icon
              :model="form"
              :rules="rules"
             
              >
              <el-row :gutter="20">
                <el-col :span="12" >
                  <el-form-item >
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.datas") }}
                    </span>
                      <el-date-picker
                      :class="mode ? 'input__day' : 'input__night'"
                        style="width:100%"
                        :size="'medium'"
                        v-model="form.date"
                        type="datetime"
                        :placeholder="$t('message.datas')">
                      </el-date-picker>
                    </el-form-item> 
                </el-col>
                <el-col :span="12" >
                  <el-form-item prop="type_expense_id">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.type_expense") }}
                    </span>
                    <select-type-expense
                      :size="'medium'"
                      :id="form.type_expense_id"
                      v-model="form.type_expense_id"
                    ></select-type-expense>
                  </el-form-item> 
                </el-col>
                
                <el-col :span="12">
                  <el-form-item prop="paid_amount">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.amount") }}
                    </span>
                    <crm-input
                      :inputValue="form.money_amount"
                      :placeholder="$t('message.amount')"
                      v-model="form.money_amount"
                      size="medium"
                      :type="'number'"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="payment_type">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.payment_type") }}
                    </span>
                    <el-select
                      class="w-100"
                      v-model="form.payment_type"
                      size="medium"
                      :placeholder="$t('message.payment_type')"
                      :class="mode ? 'input__day' : 'input__night'"
                    >
                      <el-option
                        v-for="(type, index) in payment_types"
                        :key="'payment_types' + index"
                        :label="type.label"
                        :value="type.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> 
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="account_id">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.check") }}
                    </span>
                    <select-account
                      :id="form.account_id"
                      v-model="form.account_id"
                    ></select-account>
                  </el-form-item>  
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="branch_id">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.branch") }}
                    </span>
                    <select-branch
                      :id="form.branch_id"
                      v-model="form.branch_id"
                    ></select-branch>
                  </el-form-item>  
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="currency_id">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.currency") }}
                    </span>
                    <select-currency
                      @c-change="updateCurrency()"
                      :id="form.currency_id"
                      v-model="form.currency_id"
                    ></select-currency>
                  </el-form-item>  
                  <el-form-item v-if="form.currency && !form.currency.is_active" label="Курс" prop="rate" size="small">
                    <el-input v-model="form.currency_rate" type="number"></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :span="12" >
                    <el-form-item
                      prop="comment"
                      class="label_mini">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        {{ $t("message.comment") }}
                      </span>
                      <el-input
                          type="textarea"
                          :rows="1"
                          :placeholder="$t('message.comment')"
                          :inputValue="form.comment"
                          v-model="form.comment"
                          class="text-area-el"
                          :class="mode ? 'input__day' : 'input__night'"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectTypeExpense from '@/components/filters/inventory/select-type-expense.vue';
import SelectCurrency from "@/components/filters/inventory/select-currency";
import SelectAccount from "@/components/filters/inventory/select-account";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "RegionController",
    components: { selectTypeExpense, SelectCurrency,SelectAccount },
    data() {
        return {
          payment_types: [
            { value: "card", label: "Пластик" },
            { value: "cash", label: "Наличный" },
          ],
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "paymentsExpense/rules",
            model: "paymentsExpense/model",
            columns: "paymentsExpense/columns",
            currencies: 'currency/inventory',
            mode: "MODE"

        }),
    },
    methods: {
        ...mapActions({
            update: "paymentsExpense/update",
            show: "paymentsExpense/show",
        }),
        submit(close = true) {
          this.form.currency_rate = this.form.currency ? this.form.currency.is_reverse ? _.round(1 / +this.form.currency_rate, 20) : +this.form.currency_rate : +this.form.currency_rate;
          this.form.date = moment(this.form.date).format("YYYY-MM-DD hh:mm:ss");  
          this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        updateCurrency() {
          console.log('salom');
            const currency = _.find(this.currencies, { 'id': this.form.currency_id });
            if (currency) {
                this.form.currency = currency;
                console.log(currency);
                this.form.currency_rate = currency.reverse ? currency.reversed_rate : currency.rate;
            }
        },
    },
};
</script>
